.marshalDetails {
  font-family: "Poppins", sans-serif;
  width: 100%;

  .detailRow {
    display: flex;
    margin-bottom: 12px;
    align-items: baseline;

    .detailLabel {
      font-size: 14px;
      font-weight: 500;
      min-width: 70px;
      font-weight: 500;
      color: #292929;
    }

    .detailValue {
      font-size: 14px;
      font-weight: 500;
      flex: 1;
      color: #292929;
    }
  }

  .callButton {
    width: 100%;
    background-color: #7b2bff;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px;
    font-size: 16px;
    margin-top: 8px;
    cursor: pointer;
  }
}
