.tripTableContainer {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 21px;
  margin-top: 15px;
  width: 97%;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #ffffff;

  .tripTable {
    width: 100%;
    border-collapse: collapse;
    th {
      background-color: #f5f5f5;
      text-align: left;
      padding: 12px;
      font-weight: bold;
      border-right: 1px solid #ddd;
      color: #292929;
      font-weight: 600;
      .coloumnTitle {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    tr {
      &:hover {
        background-color: #f5f5f5;
      }
    }

    td {
      height: 100%;
      padding: 12px;
      color: #454545;

      .cellConainer {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .fromAndTo {
      max-width: 240px;
    }

    .tripId {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .code {
      }
      .copyIcon {
        margin-left: 8px;
        margin-top: 0px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .timeAndDate {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 14px;
      .time {
        font-weight: 600;
      }
    }

    .details {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .noOfBookingsText {
        font-weight: 600;
      }
      .detailsBtn {
        display: flex;
        align-items: center;
        background: #f1e9ff;
        padding: 8px 12px;
        border-radius: 8px;
        border: none;
        color: #7b2aff;
        cursor: pointer;
        gap: 4px;
        font-weight: 600;

        img {
          width: 12px;
          height: 12px;
        }
      }
    }
    .marshalInfo {
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;

        strong {
          display: block;
          margin-bottom: 2px;
        }

        span {
          display: block;
          margin-top: 0;
        }
      }
      .viewDetails {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;
        color: #7b2aff;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .assigned {
      align-items: center;
      font-weight: bold;
      color: #454545;

      .textWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        .greenTick {
          width: 15px;
          height: 15px;
        }
        span {
          font-weight: 500;
        }
        .toolTipIcon {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }

      .editView {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin-top: 5px;
        color: #7b2aff;
        cursor: pointer;
        font-weight: 600;
      }
    }

    .assignBtn {
      background-color: #7b2aff;
      color: white;
      border: none;
      padding: 8px 12px;
      border-radius: 8px;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        background-color: #7b2aff;
      }
    }

    .expandedRow {
      background-color: #fafafa;

      .bookingTiles {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 12px;
        padding: 12px;

        .bookingTile {
          background: #ffffff;
          height: 80px;
          border-radius: 8px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          min-width: 180px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          &:hover {
            background-color: #fafafa;
          }

          .index {
            width: 5%;
            font-weight: bold;
            font-size: 14px;
          }
          .name {
            width: 15%;
            font-size: 14px;
            line-height: 21;
          }
          .seats {
            width: 10%;
          }
          .pickupAndDrop {
            width: 40%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .pickupAndDropTime {
            width: 15%;
            strong {
              color: #333;
              margin-bottom: 14px;
            }
          }
          .callBtnContainer {
            width: 5%;
            .callBtn {
              width: 65px;
              background: #7b2aff1a;
              color: #7b2aff;
              border-radius: 8px;
              padding: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              cursor: pointer;
              font-weight: bold;

              img {
                width: 14px;
                height: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.tooltipBox {
  background-color: #75879d;
  width: 244px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tooltipRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  color: #ffffff;
}

.tooltipRow img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.tooltipRow span {
  flex-grow: 1;
  text-align: left;
}
