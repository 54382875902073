.fmlmSummarySearchFiltersDweb {
  font-family: "Poppins", sans-serif;
  width: 100%;
  background: white;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  .filterGroup {
    margin-right: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .cityInput,
  .dateInput {
    padding: 12px;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    width: 180px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    list-style: 21px;
  }

  .routeType {
    padding: 12px;
    border: none;
    border-radius: 8px;
    width: 180px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    list-style: 21px;
  }

  .dropdownContainer {
    position: relative;
  }

  .dropdownSelectContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    padding: 0px 18px 0pc 6px;
  }

  .inputWrapper {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .dropdownIcon {
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    width: 100%;
    background: white;
    border: 1px solid #f1f1f1;
    max-height: 150px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;

    li {
      padding: 5px;
      cursor: pointer;
      &:hover {
        background: #f0f0f0;
      }
    }
  }

  .selectDropdown {
    position: absolute;
    width: 100%;
    background: white;
    border: 1px solid #f1f1f1;
    max-height: 150px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin-top: 40px;
    z-index: 1000;

    li {
      padding: 5px;
      cursor: pointer;
      &:hover {
        background: #f0f0f0;
      }
    }
  }

  .swapIcon {
    cursor: pointer;
    width: 20px;
  }

  .searchButton {
    background: #7b2aff;
    color: white;
    padding: 12px 20px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
  }

  .clearButton {
    background: rgb(227, 101, 101);
    color: white;
    padding: 12px 20px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
  }

  .calendarModalPopUp {
    top: 140px;
    position: absolute;
    z-index: 999;
    background: white;
    border: 1px solid #f1f1f1;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}
