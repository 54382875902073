.earningsView {
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  display: flex;
  gap: 12px;
  padding: 16px;
  background-color: #f5f5f5;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 328.5px;
    height: 74px;
    background-color: white;
    border-radius: 8px;
    padding: 12px;
    flex-shrink: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    .icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 13px;
      left: 8px;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      margin-left: 40px; // Push text to the right of the icon
      justify-content: center;

      .title {
        font-size: 14px;
        color: #333;
      }

      .amount {
        font-weight: bold;
        font-size: 18px;
        color: #000;
        margin-top: 4px;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 0px;
    padding-top: 0px;
    background-color: #ffffff;
    .card {
      width: 150px;
      height: 61px;
      padding: 4px 8px;
    }

    .icon {
      width: 20px;
      height: 20px;
      top: 6px;
      left: 6px;
    }

    .textContainer {
      margin-left: 36px;

      .title {
        font-size: 12px;
      }

      .amount {
        font-size: 16px;
      }
    }
  }
}
