.fmlmPaymentSummaryTile {
  font-family: "Poppins", sans-serif;
  width: 88%;
  margin: 10px auto;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  .tripHeader {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 18px;
    color: #454545;
    font-weight: 400;
    margin-bottom: 10px;

    .tripDateAndTime {
      .tripDate {
        margin-right: 10px;
      }
      .dotIcon {
        color: #999999;
      }
      .tripTime {
        margin-left: 10px;
        font-style: bold;
        font-weight: 700;
      }
    }
  }

  .tripRoute {
    margin-bottom: 10px;
    .routeFromTo {
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 22.5px;
      color: #292929;
      font-weight: 600;

      .arrowIcon {
        width: 14px;
        height: 14px;
        margin: 0 18px;
      }
    }
  }

  .tripDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #454545;
    margin-top: 18px;

    .seater {
      margin-right: 5px;
      border-radius: 41px;
      padding: 4px 8px;
      border: 1px solid #ccc;
    }
    .passengers {
      margin-left: 5px;
      border-radius: 41px;
      padding: 4px 8px;
      border: 1px solid #ccc;
    }

    .amountContainer {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 16px;
      font-weight: bold;
      text-decoration: none;
      .text {
        color: #4bae4f;
      }
      .text.contractInvalid {
        font-weight: 500;
        font-size: 12px;
        color: #dd800e;
      }
      .infoIcon {
        margin-left: 5px;
        width: 14px;
      }
      .clockIcon {
        margin-right: 5px;
        width: 14px;
      }
      .text.tripNotStarted {
        font-weight: 500;
        font-size: 12px;
        color: #292929;
      }

      &:hover {
        text-decoration: underline;
      }

      .detailsIcon {
        width: 14px;
        height: 14px;
        margin-left: 5px;
      }
    }
  }
}

.tooltipBox {
  background-color: #75879d;
  width: 230px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 9999;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01);
}
