.modalOverlay {
  font-family: "Poppins", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(0.4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  font-family: "Poppins", sans-serif;
  background: white;
  border-radius: 15px;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modalTitle {
  font-weight: bold;
  font-size: 18px;
}

.closeIcon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
