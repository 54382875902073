@media only screen and (max-width: 768px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 2% 2%;
  }

  .nav-menu {
    background-color: white;
    width: 250px;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 1;
    right: -100%;
    transition: 850ms;
  }

  .nav-menu.active {
    right: 0;
    z-index: 2;
    transition: 350ms;
    border: 1px solid grey;
  }

  .navbackground {
    background: black;
    z-index: 1;
    width: 100%;
    position: absolute;
    opacity: 0.8;
    height: 100vh;
  }

  .profileDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .operatorName {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .deals {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > img:nth-child(1) {
      margin-left: 4%;
    }
    > img:nth-child(3) {
      margin-right: 4%;
    }
  }

  .confirmButton {
    position: absolute;
    bottom: 2%;
    height: 50px;
    width: 100%;
    color: white;
    background: #ff5e03;
  }
}

@media only screen and (min-width: 768px) {
  .desktopContainer {
    background: #f8f8f8;
    width: 15vw;
    position: fixed;
    height: 100vh;
    padding: 10px 0px 0px 10px;
    .Navlogo {
      width: 50%;
    }
    img {
      padding-right: 12px;
    }
  }
  .grey {
    background: #f3f1f6;
    height: 3px;
  }

  .dealName {
    font-family: "Poppins";
    font-size: 14px;
  }

  .dealNameColored {
    font-family: "Poppins-Medium";
    font-size: 14px;
    color: #7b2bff;
  }

  .deals {
    cursor: pointer;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > a {
      display: flex;
      align-items: center;
    }
  }
  .profileDetails {
    display: flex;
    justify-content: space-between;
  }
  .bottomDetails {
    cursor: pointer;
    position: absolute;
    bottom: 5%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: strech;
  }
  .confirmButton {
    display: flex;
    cursor: pointer;
    img {
      width: 22px;
      height: 22px;
    }

    margin-top: 10px;
    height: 50px;
    font-family: "Poppins";
    font-size: 14px;
    border: none;
    box-shadow: none;
    background-color: #f8f8f8;
  }
}
