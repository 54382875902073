.dateSearchBarContainer {
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content: center;

  .wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    max-width: 95vw;
    flex-wrap: nowrap;
  }

  .label {
    white-space: nowrap;
    flex-shrink: 0;
    font-size: 12px;
    font-weight: 600;
    color: #454545;
  }

  .filter-field.date-range {
    display: flex;
    align-items: center;
    padding: 5px;
    background: #f9f9f9;
    border-radius: 5px;
    border: none;

    .date-text {
      padding: 2px 0px;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
    }

    .calendar-icon {
      margin-left: 8px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      flex-shrink: 0;
    }

    span {
      font-weight: bold;
      background: #f9f9f9;
      padding: 0px;
    }
  }
}

.calendarModal {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%); // Center the calendar modal
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: max-content;
  max-width: 90vw;
}
