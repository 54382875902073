.searchCityOverlayContainer {
  font-family: "Poppins", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .overlay-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .search-input {
      flex-grow: 1;
      padding: 0.5rem;
      height: 30px;
      font-size: 1rem;
      border: 1px solid #ccc;
      border-radius: 8px;
    }

    .close-button {
      margin-left: 1rem;
      width: 15px;
      background: none;
      border: none;
      font-size: 2rem;
      cursor: pointer;
      color: #888;
    }
  }

  .city-list {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    .city-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 1rem;
      font-size: 12px;
      line-height: 18px;
      cursor: pointer;

      &:hover {
        background-color: #f4f4f4;
      }

      .city-name {
        color: #333;
      }

      .select-text {
        color: #7b2aff;
        font-weight: 600;
      }
    }
  }
}
